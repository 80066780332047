/* src/app/routes/users/profile/profile.component.scss */
.tittle {
  font-family: "Source Sans Pro", sans-serif;
  color: #4d4f5b;
  font-size: 30px;
}
.button-style1 {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #4089fb;
  font-size: 15px;
}
.inline-block-display {
  display: inline-block;
  padding-right: 0px;
}
.body-position {
  padding-top: 60px;
}
.card-p {
  margin-bottom: 1.25rem;
  width: 100%;
  height: 170px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.user-image-style {
  margin-top: 25px;
  margin-bottom: 20px;
  margin-left: 10px;
  width: 100px;
  object-fit: contain;
}
.card-text1 {
  font-family: "Source Sans Pro", sans-serif;
  color: #4d4f5b;
  font-size: 22px;
  font-weight: bold;
  padding-top: 30px;
}
.row-card {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 30px;
}
.card-text-position {
  padding-top: 30px;
}
.white-row {
  background-color: white;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
}
.white-row-column {
  padding-top: 50px;
  padding-left: 50px;
  padding-bottom: 40px;
}
.secondary-text {
  color: #bbbbbb;
  font-size: 15px;
}
.white-row-text {
  font-size: 18px;
}
.padding-top {
  padding-top: 40px;
}
.padding-right {
  padding-right: 10px;
}
.webkit-box {
  display: -webkit-box;
}
.control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 0px;
  border-bottom-color: #e9e9f0;
  border-bottom-width: 3px;
}
.padding-pen-icon {
  padding-left: 20px;
}
.bottom-row-padding {
  padding-top: 40px;
}
.custom-input {
  border-radius: unset;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 2px;
  width: 100%;
  background-color: white;
}
.has-float-label[_ngcontent-c6] input[_ngcontent-c6]:placeholder-shown:not(:focus) + *[_ngcontent-c6],
.has-float-label[_ngcontent-c6] select[_ngcontent-c6]:placeholder-shown:not(:focus) + *[_ngcontent-c6],
.has-float-label[_ngcontent-c6] textarea[_ngcontent-c6]:placeholder-shown:not(:focus) + *[_ngcontent-c6] {
  font-size: 120%;
  opacity: 0.7;
  top: 1em;
}
.has-float-label[_ngcontent-c6] input[_ngcontent-c6],
.has-float-label[_ngcontent-c6] select[_ngcontent-c6],
.has-float-label[_ngcontent-c6] textarea[_ngcontent-c6] {
  padding-top: 1.7em;
}
.has-float-label[_ngcontent-c6] label[_ngcontent-c6],
.has-float-label[_ngcontent-c6] > span[_ngcontent-c6] {
  font-size: 95%;
}
.has-float-label {
  margin: 20px 0px 10px 0px !important;
}
.disabled {
  background-color: white;
  border: 0 !important;
}
.ng-invalid.ng-touched:not(form) + label {
  color: red;
}
.ng-invalid.ng-touched:not(form) {
  border-bottom: 2px solid red !important;
}
.content-center {
  justify-content: center;
}
.title-card {
  padding: 15px;
  font-weight: 400;
  font-size: 17px;
}
.save-button {
  text-align: right;
  line-height: 70px;
}
.center {
  text-align: center;
}
.profile-logo {
  height: 200px;
  width: 200px;
}
.image1 {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.capitals-profile {
  display: inline-block;
  font-size: 3.4em;
  width: 120px;
  height: 120px;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #666666;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
  margin: 25px;
  text-transform: uppercase;
}
.page-tit {
  padding-top: 30px;
}
.small-font-size {
  color: #565864;
  font-size: 12px;
}
.status-circle {
  width: 10px;
  height: 10px;
  line-height: 10px;
  border-radius: 50%;
  background-color: #656565;
  margin-top: -1px;
}
.status-circle.set {
  background-color: #45f89e !important;
}
.status-circle.unset {
  background-color: #e00c2c !important;
}
/*# sourceMappingURL=profile.component.css.map */
