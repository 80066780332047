<div class="wrapper page container">
  <div class="row page-tit">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'profile.title' | translate}}</h3>
      <button type="button" (click)="onSubmit()" class="btn blue-bg mg-12 float-right header-button">{{'profile.save_mod'
        | translate}}</button>
    </div>
  </div>
  <div class="row content-center">
    <div class="col-md-12 col-xs-12">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="card card-p  border-0">
            <div class="row">
              <div class="col-auto">
                <img *ngIf="logoPath" src="{{logoPath}}" class="img-responsive user-image-style">
                <div *ngIf="!logoPath && myData && myData.name && myData.surname && profile!='RESELLER'" class="capitals-profile">
                  {{myData.name[0].charAt(0) + myData.surname[0].charAt(0)}}
                </div>
                <div *ngIf="!logoPath && myData && myData.name && profile=='RESELLER'" class="capitals-profile">
                  {{myData.name[0].charAt(0)}}
                </div>
              </div>
              <div class="col">
                <div class="card-text1" *ngIf="myData">{{ myData.name }} {{ myData.surname }}</div>
                <div class="mt-2" *ngIf="profile!='SUPERADMIN' && profile!='RESELLER'">
                  <div class="d-flex align-items-center small-font-size" *ngIf="myData && !myData.pinSet">
                    <span class="status-circle unset mr-1"></span>{{'profile.pin_not_set' | translate}}
                  </div>
                  <div class="d-flex align-items-center small-font-size" *ngIf="myData && myData.pinSet">
                    <span class="status-circle set mr-1"></span>{{'profile.pin_set' | translate}}
                  </div>
                  <div class="mt-1" *ngIf="myData">
                    <button type="button" class="btn details-button-small blue-bg" (click)="openModalPin()">
                      <span *ngIf="!myData.pinSet">{{'profile.set_pin' | translate}}</span>
                      <span *ngIf="myData.pinSet">{{'profile.change_pin' | translate}}</span>
                    </button>
                    <!--<button type="button" class="btn blue-bg header-button" (click)="openModalPin()">
                      <span *ngIf="!myData.pinSet">{{'profile.set_pin' | translate}}</span>
                      <span *ngIf="myData.pinSet">{{'profile.change_pin' | translate}}</span>
                    </button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 form-padding">
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="name" id="name">
                    <label class="label custom-label" for="name"> {{'general.name' | translate}}</label>
                  </div>
                  <div class="has-float-label">
                      <input class="custom-float-label-input" type="text" placeholder=" " formControlName="username" id="username">
                      <label class="label custom-label" for="username"> {{'general.username' | translate}}
                      </label>
                  </div>
                  <div class="has-float-label">
                      <input class="custom-float-label-input " type="text" placeholder=" " formControlName="telephone" id="telephone"
                        required>
                      <label class="label custom-label" for="telephone"> {{'general.phone' | translate}} </label>
                  </div>  
                </div>  
                <div class="col-md-6 form-padding">
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="surname" id="surname">
                    <label class="label custom-label" for="surname"> {{'general.surname' | translate}} </label>
                  </div>
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="email" id="email"
                      required>
                    <label class="label custom-label" for="email"> {{'profile.email' | translate}} </label>
                  </div>               
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="profile" id="profile">
                    <label class="label custom-label" for="profile"> {{'profile.title' | translate}} </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #pinModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <ng-container *ngIf="!myData.pinSet">{{ 'profile.set_pin' | translate }}</ng-container>
      <ng-container *ngIf="myData.pinSet">{{ 'profile.change_pin' | translate }}</ng-container>
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="profilePinForm" (ngSubmit)="onSubmitPin()">
      <div class="row">
        <div class="col-md-6">
          <div class="has-float-label fl">
            <input class="custom-float-label-input" type="password" placeholder=" " formControlName="pin" id="pin">
            <label class="label custom-label" for="pin">{{'profile.pin' | translate}}</label>
          </div>
          <div class="text-danger" *ngIf="profilePinForm.get('pin').touched && profilePinForm.get('pin').invalid && profilePinForm.get('pin').errors.pattern">
            {{'profile.pin_must_match_regex' | translate}}
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-md-6">
          <div class="has-float-label fl">
            <input class="custom-float-label-input" type="password" placeholder=" " formControlName="confirmPin" id="confirmPin">
            <label class="label custom-label" for="confirmPin">{{'profile.confirm_pin' | translate}}</label>
          </div>
          <div class="text-danger" *ngIf="profilePinForm.get('confirmPin').touched && profilePinForm.get('confirmPin').invalid && (profilePinForm.get('confirmPin').errors.pattern || profilePinForm.get('confirmPin').errors.equalTo)">
            <ng-container *ngIf="profilePinForm.get('confirmPin').errors.pattern">{{'profile.pin_must_match_regex' | translate}}</ng-container>
            <ng-container *ngIf="profilePinForm.get('confirmPin').errors.equalTo">{{'profile.pin_mismatch' | translate}}</ng-container>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mt-2">
        <div class="col-md-6">
          <button type="submit" class="btn btn-block btn-primary">
            {{'general.save' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
